.footer_container{
    height: auto;
    position: relative;
    background: #005C99; 
    color: #FAFAFA;
}
.isActive{
    color: #FAFAFA;
    font-weight: 500;
}
.isNotActive{
    font-weight: normal;
    color: #dddddd;
}
.footer_main{
    padding: 40px 20px 10px 20px;
    display: flex;
    justify-content: space-around;
}
.footer_info{
    padding-right: 30px;
    border-right: 2px solid #FAFAFA;
}
.heading{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.email{
    font-weight: 500;
}
.p{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #FAFAFA;
    padding: 7px 0;
}
.footer_social_icons{
    display: flex;
    justify-content: space-evenly;
    padding: 10px 20px;
}
.social_icon{
    width: 1.4em;
    height: 1.4em;
}
.hide{
    display: none;
}
.hr{
    width: 84%;
    padding: 0px;
    margin: 0 0 0 8%;
    height: 1px;
    border: none;
    outline: none;
    background: white;
}
.copyright{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.copy{
    width: 1.6em;
    height: 1.6em;
}
.copy_p{
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
}
@media (max-width: 900px) {
    .footer_container{
        height: fit-content;
    }
    .footer_main{
        padding: 30px 20px 10px 20px;
        flex-direction: column;
        justify-content: space-around;
    }
    .footer_info{
        padding-right: 30px;
        border-right: none;
        margin-bottom: 20px;
    }
    .heading{
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: #FFFFFF;
        margin-bottom: 10px;
    }
    .email{
        font-weight: 500;
    }
    .p{
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #FAFAFA;
        padding: 7px 0;
    }
    .footer_links{
        margin: 10px 0;
    }
    .footer_social{
        text-align: center;
    }
    .footer_social_icons{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 10px 0;
    }
    .social_icon{
        width: 1.4em;
        height: 1.4em;
    }
    
    .hr{
        width: 84%;
        padding: 0px;
        margin: 0 0 0 8%;
        height: 1px;
    }
    .copyright{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }
    .copy{
        width: 1.6em;
        height: 1.6em;
    }
    .copy_p{
        font-family: Ubuntu;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
    }
}