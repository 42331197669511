.whyus_container{
    height: auto;
    position: relative;
    text-align: center;
    padding: 10px 0;
    background: #FAFAFA;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.whyus_heading{
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 41px;
    color:  #005C99;
    padding: 20px 0;
}
.whyus_subheading{
    width: 100%;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: #0C0C0C;
    padding: 20px 0;
}
.whyus_logos{
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}
.whyus_card{
    position: relative;
    padding: 28px 3px;
    width: 214px;
    height: auto;
    margin: 10px 15px;
    transition:all 0.3s ease-in-out;
    background-color: #F4F4F4;
    border-radius: 8px;
}
.whyus_card:hover{
    box-shadow: 0 2.8125rem 3.75rem 0 rgb(34 34 34 / 10%);
    -webkit-box-shadow: 0 2.8125rem 3.75rem 0 rgb(34 34 34 / 10%);
    -moz-box-shadow: 0 2.8125rem 3.75rem 0 rgb(34 34 34 / 10%);
    background-color: #f6f6f6; 
}
.image_card{
    position: relative;
    width: 190px !important;
    height: 160px !important;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.whyus_image{
    z-index: 0;
}
.whyus_card_p{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    padding-top: 10px;
}



@media (max-width: 600px) {
    .whyus_container{
        height: fit-content;
    }
    .whyus_subheading{
        width: fit-content;
        font-size: 20px;
        line-height: 24px;
    }
    .whyus_logos{
        padding: 10px 0;
        display: flex;
        flex-wrap: wrap; 
    }
    .whyus_card{
        width: fit-content;
        height: 240px;
        margin: 10px;
    }
    .image_card{
        width: 150px !important;
        height: 160px !important;
    }
    .whyus_card_p{
        font-size: 14px;
        line-height: 16px;
    }
}