.container{
    width: 100%;
    height: 613px;
    background: #FAFAFA;
    display: flex;
    justify-content: space-around;
    align-content: center;
    align-items: center;
}
.container img{
    width: 100%;
    max-width: 500px;
    height: auto;
}
.card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 5px 10px;
}
.heading{
    width: fit-content;
    height: 41px;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 41px;
    color: #005C99;
    margin: 10px 0;
}
.description{
    width: 533px;
    height: 266px;
    text-align: justify;
    text-justify: inter-word;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #545454;  
    padding: 0 20px;   
}

@media (max-width: 600px) {
    .container{
        width: 100%;
        height: fit-content;
        flex-direction: column-reverse;
        padding: 10px;
    }
    .card{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding: 5px 10px;
    }
    .heading{
        height: fit-content;
        font-size: 22px;
        line-height: 41px;
    }
    .description{
        width: fit-content;
        height: auto;     
    }
}