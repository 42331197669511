.container{
    position: relative;
    background: #FAFAFA;
    text-align: center;
    padding: 20px 0;
}
.heading{
    color:  #005C99;
    padding: 40px 0;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 41px;
}
.card_container{
    width: 100%;
    height: 130px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
}
.description{
    width: 515px;
    height: 105px;
    text-align: justify;
    text-justify: inter-word;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

/* Dark grey- text */

color: #545454;
}

@media (max-width: 980px) {
    .card_container{
        width: 100%;
        height: fit-content;
        flex-direction: column;
        padding: 10px;
    }
    .heading{
        padding: 20px 0 0 0;
        margin: 0;
        text-align: center;
    }
    .description{
        width: fit-content;
        height: auto;
        padding: 0 15px;
        margin-bottom: 20px;
    }
    
}