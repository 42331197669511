.container{
    height: 300px;
    position: relative;
    background: #FAFAFA;
    text-align: center;
    padding: 20px 0;
}
.heading{
    color:  #005C99;
    padding: 30px 0;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 41px;
    margin-bottom: 30px;
}
.sub_heading{
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 32px;
    color: #545454;
    text-align: left;
}
.card_container{
    width: 100%;
    height: 130px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.description{
    width: 320px;
    height: 125px;
    text-align: justify;
    text-justify: inter-word;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #545454;
    padding-top: 10px;
}

@media (max-width: 980px) {
    .container{
        width: 100%;
        height: fit-content;
        flex-direction: column;
        padding: 10px;
    }
    .card_container{
        width: fit-content;
        height: auto;
        flex-direction: column;
        padding: 10px; 
    }
    .heading{
        padding: 20px 0 0 0;
        margin: 0;
        text-align: center;
    }
    .description{
        width: fit-content;
        height: auto;
        font-weight: 400;
        margin-bottom: 20px;
    }
    .sub_heading{
        font-size: 26px;
    }
}