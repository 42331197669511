.carousel_container{
    width: 100%;
    height: auto;
    position: relative;
    text-align: center;
    padding: 10px 0;
    background: #FAFAFA;
    scroll-margin-top: 72px;
    transition: all .4s linear !important;
}
.heading{
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 41px;
    text-align: center;
    color: #005C99;
    margin-bottom: 10px;
    padding: 20px 0 10px 0;
}
.carousel__dots {
    display: flex;
    list-style: none;
    padding-top: 5px;
    justify-content: space-between;
    justify-self: center;
    align-items: center;
    transition: .5s ease-in;
}
  .carousel__dot_mob{
    display: none;
  }
  .carousel__button_mob{
    display: none;
  }
  .carousel__dot {
      width: 40px;
      height: 40px;
      cursor: pointer;
      position: relative;
      padding: 5px;
      outline: 0;
      border-radius: 50%;
      border: 1px solid #005C99;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      margin: 10px 0px;
  }
    
  .carousel__dot:after {
      background: #FFFFFF;
      color: #005C99;
      width: 100%;
      height: 40px;
      border-radius: 50px;
      transition: .5s ease-in;
  }
  .carousel__dot.is_selected {
    background-color: #005C99 !important;
    color: #FFFFFF !important;
    opacity: 1;
    transition: .5s ease-in;
  }
  .dotted {
    border-top: 1px dotted #005C99;
    width: 160px;
  }
  .dotted:nth-last-child(2){
    display: none;
  }
  .carousel {
    position: relative;
    width: 100%;
    transition: all .4s linear !important;
  }
  
  
  .carousel__slide {
    margin-right: auto;
    margin-left: auto;
    display: none;
    max-width: 100%;
    text-align: center;
    transition: all .4s linear !important;
  }
  .carousel__slide__active{
      display: block;
  }
  .carousel__slide_outer_div{
    background-color: inherit;
    padding: 10px 40px;
  }
  .carousel__slide__title{
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    margin-top: 5px;
    margin-bottom: -30px;
  }
  .carousel__slide_image{
    padding-top: 0px;
  }
  .carousel__slide_image_size img{
    width: 100%;
    max-width: 500px;
    height: auto;
  }
  .carousel_slide__content {
    width: 40%;
    height: 50px;
    margin-left: 35%;
    margin-right: 25%;
    margin-bottom: 20px;
    margin-top: -50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
  }
  .carousel_slide__content_p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #545454;
    text-align: left;
  }
  .carousel__button {
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    padding: 0;
    color: #005C99;
    transition: all .5s ease-in;
  }
  
  
  .carousel__button__prev {
    left: 320px;
  }
  
  .carousel__button__next {
    right: 320px;
  }
  
  .btn_icon{
    width: 30px;
    height: 30px;
    outline: none;
    text-align: center;
    font-weight: 400;
    padding: 2px;
    border: 1px solid gray;
    border-radius: 50%;
    transition: all .4s linear !important;
  }
  
  @media (max-width: 600px) {
    .carousel__dot{
      display: none;
    }
    .dotted{
      display: none;
    }
    .carousel__dot_mob{
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin: 10px 0px;
    }
    .carousel__dots{
      display: flex;
      padding-top: 10px;
      justify-content: center;
      justify-self: center;
      align-items: center;
    }
    .carousel_slide__content {
      width: 90%;
      height: auto;
      margin-left: 5%;
      margin-right: 5%;
      margin-bottom: 10px;
    }
    .carousel__button_mob{
      width: 20px;
      height: 20px;
      outline: none;
      border: none;
      border-radius: 50%;
      display: block;
      background-color: gray;
      color: #FAFAFA;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin: 10px 10px;
    }
    .carousel__slide__title{
      margin-top: 5px;
      margin-bottom: -10px;
    }
    .carousel_slide__content {
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
      margin-bottom: 20px;
      margin-top: -20px;
    }
    .carousel__button {
      outline: 0;
      color: #FFFFFF;
    }
    .carousel__button__prev {
      left: 5%;
    }
    
    .carousel__button__next {
      right: 5%;
    }
    .btn_icon{
      border: 1px solid #FFFFFF;
    }
}