.container{
    width: 100%;
    height: 613px;
    background: #F4F4F4;
    padding: 30px 30px;
}
.heading{
    width: 360px;
    padding-top: 40px;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 41px;
    text-align: center;
    color: #005C99;
}
.btn{
    padding: 10px 20px;
    width: fit-content;
    border-radius: 50px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    outline: none;
    background: #005C99;
    border: 2px solid #67bef8;
    box-sizing: border-box;
    color: #FAFAFA;
    cursor: pointer;
  }
.card_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    width: 100%;
    height: 550px;
    overflow-x: hidden;
}
.card_container::-webkit-scrollbar {
    width: 0px;
}
.card_left{
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.card{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 592px;
    height: 174px;
    background: #FAFAFA;
    box-sizing: border-box;
    padding: 20px;
    margin: 40px 40px;
    transition: .3s;
    cursor: pointer;
}
.card:hover{
    box-shadow: 0 2.8125rem 3.75rem 0 rgb(34 34 34 / 10%);
    -webkit-box-shadow: 0 2.8125rem 3.75rem 0 rgb(34 34 34 / 10%);
    -moz-box-shadow: 0 2.8125rem 3.75rem 0 rgb(34 34 34 / 10%);
}
.sub_heading{
    width: fit-content;
    height: 37px;
    margin: 10px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #545454;
}
.description{
    width: 550px;
    height: 76px;
    text-align: justify;
    text-justify: inter-word;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #545454;    
}

@media (max-width: 600px) {
    .container{
        width: 100%;
        height: fit-content; 
        padding: 10px;
    }
    .card_container{
        flex-direction: column; 
        
    }
    .card_left{
        position: relative;
        top: 0;
        left: 0;
    }
    .heading{
        width: fit-content;
    }
    
    .card{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: fit-content;
        height: fit-content;
        background: #FAFAFA;
        padding: 10px;
        margin: 20px;
    }
    
    .sub_heading{
        width: fit-content;
        height: auto;
        margin: 0 0 10px 0;
    }   
    .description{
        width: fit-content;
        height: auto;
        text-align: justify;
        text-justify: inter-word;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #545454;    
    } 
}