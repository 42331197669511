.container {
    position: relative;
    top: 67px;
    left: 0;
    width: 100%;
    height: 35.625rem;
    background: #005C99;
    display: flex;
  }
  
  .text_card{
    width: 50%;
    height: 30.625rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  .text_heading{
    width: 450px;
    text-align: center;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 41px;
    color: #FFFFFF;
  }
  
  .btn{
    margin-top: 30px;
    padding: 10px 20px;
    width: fit-content;
    border-radius: 50px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    outline: none;
    background: #005C99;
    border: 2px solid #67bef8;
    box-sizing: border-box;
    color: #FAFAFA;
    cursor: pointer;
    opacity: 0.8;
    transition:all 0.1s ease-in-out;
  }
  .btn:hover{
    opacity: 1;
  }
  .btn:active{
    background-color: #67bef8;
    text-shadow: 0px 0px 0px #67bef8 !important;
  }
  .image_card{
    margin-top: 10px;
    width: 50%;
    height: 30.625rem;
  }
  .image{
    width: 550px !important;
    height: 463px !important;
  }
  @media (max-width: 600px) {
      .container {
        position: relative;
        top: 61px;
        left: 0;
        width: 100%;
        height: 100vh;
        background: #005C99;
        display: flex;
        flex-direction: column-reverse;
      }
     
      .text_card{
        width: 100%;
        height: 30.625rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        align-content: center;
        margin-bottom: 110px;
      }
      .text_heading{
        width: fit-content;
        font-weight: bold;
        font-size: 32px;
        line-height: 37px;
        color: #FFFFFF;
      }
  
      .image_card{
        width: 100%;
        height: 50vh;
        margin: 0;
        margin-top: 60px;
      }
      .image{
        margin-top: 40px;
        width: 100% !important;
        max-width: 550px;
        height: auto !important;
      }
  }