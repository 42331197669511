.client_container{
    width: 100%;
    height: fit-content;
    position: relative;
    background-color: #F4F4F4;
    overflow-x: scroll;
    overflow-y: hidden;
}
.client_container::-webkit-scrollbar {
    width: 0px;
}
.client_heading{
    width: auto;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    text-align: center;
    color: #005C99;
    padding: 0 20px;
}
.client_logos{
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 100%;
}
.client_card_image_span{
    width: 133px;
    height: 82px;
    margin: 5px 20px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.client_card_image_span:last-child, .client_card_image_span:nth-last-child(2){
    margin: 5px 50px; 
}

.client_card_image_span .client_card_image{
    width: 100%;
    max-width: fit-content;
    height: auto;
    object-fit: cover;
}

@media (max-width: 600px) {
    .client_container{
        width: 100%;
        height: fit-content;
        position: relative;
        background-color: #FAFAFA;
        padding-top: 5px;
        margin-bottom: 0px ;
        overflow-x: scroll;
        overflow-y: hidden;
    }
    .client_container::-webkit-scrollbar {
        width: 0px;
    }
    .client_heading{
        display: inline-block;
        font-size: 24px;
        line-height: 28px;
        color: #0C0C0C;
        padding: 0 5px;
    }
    .client_logos{
        width: 1100px;
    }
    .client_card_image_span{
        width: 133px;
        height: 82px;
        margin: 0 5px;
    }
    .client_card_image{
        width: 133px;
        height: 82px;
    }
}