.container{
    width: 100%;
    height: fit-content;
    position: relative;
    background-color: #F4F4F4;
    overflow-x: scroll;
    overflow-y: hidden;
}
.container::-webkit-scrollbar {
    width: 0px;
}
.heading{
    width: auto;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    text-align: center;
    color: #005C99;
    padding: 0 20px;
}
.logos{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 100%;
}
.card_image_span{
    width: 133px;
    height: 82px;
    margin: 0px 20px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.card_image_span .card_image{
    width: 100%;
    max-width: fit-content;
    height: auto;
    object-fit: cover;
}

@media (max-width: 600px) {
    .container{
        width: 100%;
        height: fit-content;
        position: relative;
        background-color: #FAFAFA;
        padding-top: 5px;
        margin-bottom: 0px ;
        overflow-x: scroll;
    }
    .container::-webkit-scrollbar {
        width: 0px;
    }
    .heading{
        display: inline-block;
        font-size: 24px;
        line-height: 28px;
        color: #0C0C0C;
        padding: 0 5px;
    }
    .logos{
        width: 1100px;
    }
    .card_image_span{
        width: 133px;
        height: 82px;
        margin: 0 5px;
    }
    .card_image{
        width: 133px;
        height: 82px;
    }
}