.container{
    height: auto;
    position: relative;
    text-align: center;
    padding: 10px 0;
    background: #FAFAFA;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.heading{
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 41px;
    text-align: center;
    color:#005C99;
    padding: 20px 0 20px 0;
}
.subheading{
    width: 100%;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: #0C0C0C;
    padding: 20px 0;
}
.logos{
    padding: 10px 0;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.card{
    position: relative;
    padding: 28px 3px;
    width: 214px;
    height: auto;
    margin: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    transition:all 0.3s ease-in-out;
    border-radius: 8px;
    background-color: #F4F4F4;
}
.card:hover{
    box-shadow: 0 2.8125rem 3.75rem 0 rgb(34 34 34 / 10%);
    -webkit-box-shadow: 0 2.8125rem 3.75rem 0 rgb(34 34 34 / 10%);
    -moz-box-shadow: 0 2.8125rem 3.75rem 0 rgb(34 34 34 / 10%);
    background-color: #f6f6f6; 
}

.image_card{
    position: relative;
    width: 150px !important;
    height: 150px !important;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.image{
    width: 100%;
    z-index: 0;
}
.card_p{
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    position: relative;
    bottom: center;
}
@media (max-width: 600px) {
    .container{
        height: fit-content;
    }
    .subheading{
        width: fit-content;
        font-size: 20px;
        line-height: 24px;
    }
    .logos{
        padding: 10px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .card{
        width: fit-content;
        height: auto;
        margin: 10px;
    }
    .image_card{
        width: 150px !important;
        height: 160px !important;
    }
}