.container{
    height: 212px;
    position: relative;
    background: #FAFAFA;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}
.card{
    flex-direction: column;
    justify-content: center;
}
.heading{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    text-align: center;
    color: #005C99;
    padding: 2px;
    margin: 0;
}
.p{
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #545454;
    padding: 2px;
    margin: 0px;
}
@media (max-width: 600px) {
    .container{
        height: auto;
    }
    .card{
        margin: 10px;
    }
}