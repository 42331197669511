.container{
    height: 550px;
    position: relative;
    background: #F0F0F0;;
}
.heading{
    padding: 60px 0;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 41px;
    text-align: center;
    color: #005C99;   
}
.sub_heading{
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #005C99;
}
.card_container{
    width: 100%;
    background-color: #F0F0F0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.card{
    width: 48%;
    height: 250px;
    position: relative;
    background-color: #FAFAFA;
    overflow: hidden;
}
.withOutHover{
    position: relative;
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    background-color: #FAFAFA;
    z-index: 0;
    transition: 0.1s ease;
}
.cardhover_info{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
}

.image{
    position: relative;
    border-radius: 50% !important;
}
.p{
    position: relative;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #545454;
} 

.effect:first-child .cardhover_info {
    width: 100%;
    height: 250px;
    position:absolute;
    margin: 0 0 0 -100%;
    left: 0;
    top: 0;
    cursor:pointer;
    transition: .4s ease-in-out;
}
.effect:nth-child(2) .cardhover_info{
    width: 100%;
    height: 250px;
    position:absolute;
    margin: 0 -100% 0 0;
    bottom: 0;
    left: 100%;
    right: 0;
    cursor:pointer;
    transition: .4s ease-in-out;
}
.effect:hover div.cardhover_info:nth-child(2) {
    width: 100%;
    background-color: #FAFAFA;
    margin: 0;
    left: 0;
    opacity: 2;
    z-index: 9;
}
.effect:hover div.cardhover_info:first-child{
    width: 100%;
    background-color: #FAFAFA;
    margin: 0;
    bottom: 0;
    right: 0;
    opacity: 2;
    z-index: 9;
}

.box{
    position: relative;
    width: 100%;
    padding: 0 100px;
    display: flex;
    justify-content: space-between;
}
.box_icons{
    width: 20%;
    display: flex;
    justify-content: space-evenly;
}
.btn{
    position: relative;
    width: fit-content;
    height: 38px;
    color: #005C99;
    border: 2px solid #67bef8;
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #FAFAFA;
    padding: 5px 10px;
    cursor: pointer;
}

@media (max-width: 720px) {
    .container{
        width: 100%;
        height: fit-content;
        flex-direction: column;
        padding: 10px;
    }
    .card_container{
        width: 100%;
        height: fit-content;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
    }
    .heading{
        padding: 20px 0 ;
        margin: 0;
        font-family: Ubuntu;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 41px;
        text-align: center;
        color: #005C99;   
    }
    .sub_heading{
        font-family: Ubuntu;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 28px;
        color: #005C99;
    }
    .card{
        width: 95%;
        height: auto;
        padding: 10px;
        margin: 20px 0;
        overflow: hidden;
    }
    .card_info{
        padding: 0 10px;
    }
    .p{
        width: 80%;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
    }
    
    .withOutHover{
        position: relative;
        width: 95%;
        height: auto;
    }
    .cardhover_info{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-content: center;
        align-items: center;
    }
    
    .p{
        position: relative;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        color: #545454;
    } 
    
    .effect:hover div.cardhover_info {
        width: 100%;
        background-color: #FAFAFA;
        margin: 0;
        bottom:0px;
        right:0px;
        opacity: 1;
        z-index: 12;
    }
    
    
    .effect:first-child .cardhover_info {
        height: 100%;
        margin: 0 0 0 -100%;
        transition: .4s ease-in-out;
    }
    .effect:nth-child(2) .cardhover_info{
        height: 100%;
        margin: 0 -100% 0 0;
        transition: .4s ease-in-out;
    }
    
    .box{
        position: relative;
        width: 100%;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
    }
    .box_icons{
        width: 30%;
        display: flex;
        justify-content: space-evenly;
    }
    .btn{
        position: relative;
        width: fit-content;
        height: auto;
        color: #005C99;
        border: 2px solid #67bef8;
        box-sizing: border-box;
        border-radius: 10px;
        background-color: #FAFAFA;
        padding: 5px 10px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 15px;
        cursor: pointer;
    }
        
}
@media (max-width: 1200px){
    .box{
        padding: 0 5%;
    }
}
