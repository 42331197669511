.nav{
    width: 100%;
    height: 67px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 1px 24px;
    z-index: 10;
    background: #005C99;
}
.list_item{
    display: flex;
    flex-direction: row;
}
.item{
    margin-left: 40px;
    height: 18px;
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: #dddddd;
    cursor: pointer;
    transition:all 1s ease-in-out;
}

.isActive{
    color: #FAFAFA;
    font-weight: 500;
}
.isNotActive{
    font-weight: normal;
    color: #dddddd;
}
.btn{
    width: 109px;
    height: 38px;
    background: #005C99;
    border: 2px solid #67bef8;
    box-sizing: border-box;
    border-radius: 20px;
    color: #FAFAFA;
    padding: 5px 10px;
    cursor: pointer;
    opacity: 0.8;
    transition:all 0.1s ease-in-out;
}
.btn:hover{
    opacity: 1;
}
.btn:active{
    background-color: #67bef8;
    text-shadow: 0px 0px 0px #67bef8 !important;
}

.mobile_menu{
    display: none;
}
@media (max-width: 980px) {
    .mobile_menu{
        background-color: #005C99;
        display: inline-block;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 11;
    }
    .menu_icon{
        width: 2em;
        height: 2em;
        color: #FAFAFA;
        margin: 15px 15px 0 0px;
        z-index: 11;
    }
    .logo{
        margin-left: 10px !important;
    }
    .nav{
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        flex-direction: row;
        padding: 0;
        z-index: 10;
        background: #005C99;
    }

    .list_item{
        display: none;
    }
    .list_item_is_mobile_view{
        width: 100%;
        height:  92vh;
        position: absolute;
        left: 0;
        top: 60px;
        display: flex;
        align-content: center;
        flex-direction: column;
        background-color: #FAFAFA;
        z-index: 11;
    }
    .item:first-child{
        margin-top: 20px;
    }
    .item{
        margin: 5px 5px 5px 20px;
        height: 36px;
        position: relative;
        display: block;
        font-family: Ubuntu;
        font-style: normal;
        font-weight: normal;
        font-size: 19px;
        line-height: 23px;
        color: #000000;;
    }
    .isActive{
        color: #005C99;
        font-weight: 500;
    }
    .isNotActive{
        font-weight: normal;
        color: #000000;
    }
    .card_item{
        position: relative;
    }
    .btn{
        width: 50%;
        height: 36px;
        text-align: center;
        background: #005C99;
        border: 2px solid #005C99;
        box-sizing: border-box;
        border-radius: 20px;
        color: #FAFAFA;
        padding: 5px 10px;
    }
    
}