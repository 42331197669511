.container{
    position: relative;
    text-align: center;
    padding: 10px 0;
    background: #F4F4F4;
    display: flex;
    flex-direction: column;
    align-items: center;
    scroll-margin-top: 72px;
}
.images{
    width: 100%;
    height: 100%;
}
.heading{
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 41px;
    text-align: center;
    color: #005C99;
    padding: 20px 0 20px 0;
}
.subheading{
    width: 100%;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: #0C0C0C;
    padding: 20px 0;
}
.carousel_container {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.imageOuterDiv{
    position: relative;
    width: 100% !important;
    height: 60% !important;
}

.carousel_wrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.carousel_content_wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.carousel_content {
    display: flex;
    transition: all 350ms linear;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel_content::-webkit-scrollbar{
    display: none;
}

.carousel_content > * {
    width: 50%;
    flex-shrink: 0;
    flex-grow: 1;
}
.active_carosel_content{
    text-align: center;
}

.carousel_content .show_3 > * {
    width: calc(100% / 3);
}
.carousel__slides{
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: inherit;
    margin-top: -30px;
    margin-bottom: 20px;
    opacity: 3;
    z-index: 2;
}
.is_active{
    background-color: grey !important;
}

.carousel_indicator_btn{
    background-color: #FAFAFA;
    width: 15px;
    height: 15px;
    border: none;
    border-radius: 50%;
    outline: none;
    margin: 2px;
}


.card{
    position: relative;
    width: 650px !important;
    height: 362px !important;
    display: flex;
}
.is_iot_card{
    background-color: #FAFAFA !important;
}
.image_container{
    position: relative;
}
.image_card_container{
    position: relative;
    width: 100% !important;
    height: 20rem !important;
    padding: 5px !important;
}

.image_card{
    width: 360px;
    height: 90px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    color: #FFFFFF;
    background: rgba(0, 92, 153, 0.71);
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 1;
    transition: .6s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.image_overlay{
    width: 100%;
    height: 100%;
    background: rgba(230, 230, 230, 0.986);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    transition: .6s ease;
    padding: 30px 40px;
}
.image_container:hover .image_overlay{
    opacity: 3;
    z-index: 11;
}
.image_overlay_heading{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #005C99;
}
.image_overlay_p{
    width: 600px;
    padding: 20px 0px;
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #005C99;
}
.image_size_outer_div_longwidth{
    position: relative;
    width: 150px !important;
    height: 60px !important;
    padding: 10px;
    display: inline-block;
}
.image_size_outer_div{
    position: relative;
    width: 60px !important;
    height: 60px !important;
    padding: 10px;
    display: inline-block;
}
.image_overlay_subheading{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #005C99;
    padding-bottom: 10px;
}
.image_overlay_card{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.mob_carousel_content{
    display: none;
}
@media (max-width: 720px) {
    .container{
        position: relative;
        height: fit-content;
        scroll-margin-top: 65px;
    }
    .subheading{
        width: fit-content;
        font-size: 20px;
        line-height: 24px;
    }
    .carousel_container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .carousel_content .show_3 > * {
        width: 100%;
    }
    .carousel_wrapper {
        display: flex;
        width: 100%;
        position: relative;
    }
    
    .carousel_content_wrapper {
        overflow: hidden;
        width: 100%;
        height: 100%;
    }
    .carousel_content > * {
        width: 100%;
        flex-shrink: 0;
        flex-grow: 1;
    }  
    .carousel_content{
        display: none;
    }
    .mob_carousel_content {
        display: flex;
        transition: all 350ms linear;
        -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
        scrollbar-width: none;  /* hide scrollbar in Firefox */
    }
    
    .carousel_content::-webkit-scrollbar{
        display: none;
    }
    .carousel__slides{
        margin-top: -23px;
    }
    .carousel_indicator_btn{
        width: 10px;
        height: 10px;
    }
    .card{
        position: relative;
        width: 650px !important;
        height: 262px !important;
        display: flex;
    } 
   
    .image_container{
        position: relative;
    }
    .image_card_container{
        position: relative;
        width: 100vw !important;
        height: 14rem !important;
        padding: 5px !important;
    }
    .image_card{
        width: 160px;
        height: 50px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #FFFFFF;
        background: rgba(0, 92, 153, 0.71);
        border-radius: 5px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        opacity: 1;
        transition: .6s ease;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
    .image_overlay{
        width: 100%;
        height: 42vh;
        background: rgba(230, 230, 230, 0.986);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        transition: .6s ease;
        padding: 10px;
    }
    .image_container:hover .image_overlay{
        opacity: 3;
        z-index: 11;
    }
    .image_overlay_heading{
        padding-top: 15px;
        font-size: 14px;
        line-height: 15px;
    }
    .image_overlay_p{
        width: fit-content;
        padding: 10px 0px;
        font-size: 11px;
        line-height: 12px;
    }
    .image_overlay_subheading{
        font-size: 10px;
        line-height: 11px;
        padding-bottom: 5px;
    }
    .image_size_outer_div_longwidth{
        position: relative;
        width: 80px !important;
        height: 40px !important;
        padding: 5px;
        display: inline-block;
    }
    .image_size_outer_div{
        position: relative;
        width: 40px !important;
        height: 40px !important;
        margin: 5px;
        padding: 5px;
        display: inline-block;
    }
    .image_overlay_card{
        display: flex;
        flex-direction: row;
    } 
}